import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";

import Homepage from "./homepage/index";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import ForgotPassword from "./Auth/ForgotPassword";
import Podcast from "./homepage/Podcast";
import Course from "./homepage/Course"
import Detail from "./homepage/Detail";
import Blog from "./homepage/Blog";
import BlogDetail from "./homepage/BlogDetail";
import About from "./homepage/About";
import Faq from "./homepage/Faq";
import Terms from "./homepage/Terms";
import Privacy from "./homepage/Privacy";
import Payment from "./homepage/Payment";
import PodcastList from "./homepage/PodcastList";
import Contact from "./homepage/Contact";
import Mentor from "./homepage/Mentor";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <Routes>
          <Route path="/" element={<Homepage />}/>
          <Route path="/podcast" element={<Podcast />}/>
          <Route path="/course" element={<Course />}/>
          <Route path="/detail" element={<Detail />}/>
          <Route path="/terms-of-service" element={<Terms />}/>
          <Route path="/privacy-policy" element={<Privacy />}/>
          <Route path="/payment" element={<Payment />}/>
          <Route path="/podcast-list" element={<PodcastList />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/blog" element={<Blog />}/>
          <Route path="/blog-detail" element={<BlogDetail />}/>
          <Route path="/faq" element={<Faq />}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/forgot-password" element={<ForgotPassword/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/mentor" element={<Mentor/>}/>
        </Routes>
      </Router>
    </Fragment>
  );
};

export default Index;
