import React, { useState, Fragment, useRef } from "react";
import CardAccordion from "../../component/card/CardAccordion";

import Navbar from "../../component/Navbar/Navbar";
import Footer from "./../../component/footer/Footer";

const Faq = (props) => {
    const faqDetail = [
        {
          title: "Is It Subscription?",
          desc: "Our learning platform operates on a subscription-based model. Subscribers enjoy uninterrupted access to a wide range of courses and content throughout their subscription period.",
        },
        {
          title: "I Don't Have The Software, Is It Provided?",
          desc: "Absolutely! We understand the importance of having the right tools. Most of our courses include access to the necessary software, ensuring that you have everything you need for a seamless learning experience.",
        },
        {
          title: "How do I buy more than 1 class at a time?",
          desc: "Purchasing multiple classes is simple. During the checkout process, you can add multiple classes to your cart and complete a single transaction, streamlining the process for your convenience.",
        },
        {
          title: "Will This Class Guarantee Me To Be Good?",
          desc: "While our courses provide valuable knowledge and skills, individual success may vary. Your dedication and practice play a crucial role in mastering the content and applying it effectively.",
        },
        {
          title: "What is Forever Access?",
          desc: "Forever Access ensures that once you've enrolled in a class, you retain access to the course materials indefinitely. Revisit the content at any time, allowing for continuous learning and skill retention.",
        },
        {
          title: "How is the Learning System?",
          desc: "Our learning system is designed for optimal engagement and effectiveness. It combines interactive elements, comprehensive resources, and a user-friendly interface to enhance your learning journey.",
        },
        {
          title: "Is it permissible to share an account with others?",
          desc: "Sharing accounts is not allowed. Each user must have their own account to ensure personalized progress tracking and a secure learning environment.",
        },
        {
          title: "Can I still access the material when I haven't accessed the class for a long time?",
          desc: "Yes, you can! Your access to course materials remains available even if you haven't logged in for an extended period. We encourage continuous learning at your own pace.",
        },
        {
          title: "Bagaimana Prosedur Bertanya Kepada Mentor?",
          desc: "The procedure for asking questions to mentors involves accessing the class discussion forums or designated communication channels provided within each course.",
        },
        {
          title: "Can I Refund/Change Class When I Have Purchased?",
          desc: "Refund and class change policies vary. Please refer to our refund and course change policy for detailed information on eligibility and procedures.",
        },
      ];
      
    return (
        <Fragment>
            <div className="overflow-hidden bg__gray-1">
                <div className="bg__purple position-relative">
                    <Navbar />
                    <section className="position-relative overflow-hidden">
                        <img src="./../images/Group 3086.png" className="path__13 blog" alt="" />
                        <div className="container position-relative z-2">
                            <img
                                src="./../images/ZZZ - 2021-07-12.png"
                                className="path__12 blog d-none d-sm-block"
                                alt=""
                            />
                            <div className="text-center position-relative z-2">
                                <div className="position-relative d-inline-block mb-5">
                                    <img
                                        src="./../images/Highlight.png"
                                        className="path__10 blog d-none d-sm-block"
                                        alt=""
                                    />
                                    <h1 className="color__white bold text-sm-center font__size--50 text__50-1024 text__50-sm text__50-mm text__50-xs lh__5">Hi, How can we help you?</h1>
                                </div>

                                <div className="wrapper__search-wrap d-flex align-items-center">
                                    <input type="text" placeholder="Search article..." className="bold font__size--16 text__16-1024" />
                                    <button className="bold font__size--16 text__16-1024 color__white btn btn__purple shadow rounded__50">Search</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section>
                    <div className="container">
                        <div className="row">


                            <div className="col-md-6">
                                {
                                    faqDetail.map((obj, i) => {
                                        return i < faqDetail.length / 2 ?
                                            <div className="mb-4">
                                                <CardAccordion title={obj.title} desc={obj.desc} />
                                            </div>
                                            : ""
                                    })
                                }
                            </div>

                            <div className="col-md-6">
                                {
                                    faqDetail.map((obj, i) => {
                                        return i >= faqDetail.length / 2 ?
                                            <div className="mb-4">
                                                <CardAccordion title={obj.title} desc={obj.desc} />
                                            </div>
                                            : ""
                                    })
                                }
                            </div>

                        </div>

                    </div>
                </section>

                <Footer />
            </div>
        </Fragment>
    );
};

export default Faq;
