import React, { useState, Fragment, useRef } from "react";
import { Range, getTrackBackground } from "react-range";

import Navbar from "../../component/Navbar/Navbar";
import Footer from "./../../component/footer/Footer";
import CardCourse from "../../component/card/CardCourse";
import CardCommand from "../../component/card/CardCommand";
import CardReview from "../../component/card/CardReview";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Detail = (props) => {

  const courses = [
    {
      title: "UI/UX Design for Beginners",
      review: 7,
      tag: "Design",
      img: "./../images/Image.jpg",
    },
    {
      title: "Introduction to Programming",
      review: 7,
      tag: "Programming",
      img: "./../images/Image-1.jpg",
    },
    {
      title: "Marketing Essentials",
      review: 7,
      tag: "Marketing",
      img: "./../images/Image-2.jpg",
    },
    {
      title: "Computer Science",
      review: 7,
      tag: "Computer Science",
      img: "./../images/Image-3.jpg",
    },
  ];

  const video = useRef(null);

  const [play, setPlay] = useState(false);


  const tootlePlay = () => {
    if (!play) {
      video.current.play();
    } else {
      video.current.pause();
    }

    setPlay(!play);
  };

  useEffect(() => {
    const videoData = document.getElementById("video__play")
    videoData.addEventListener("pause", () => {
      setPlay(!play);
    });
  }, [play])


  return (
    <Fragment>
      <div className="overflow-hidden bg__gray-1">
        <div className="bg__purple position-relative">
          <Navbar />
          <section className="position-relative">
            <div className="container">
              <div className="row mb-4">
                <div className="col-lg-8 mb-4 mb-lg-0">
                  <div className="wrapper__video position-relative mb-4 mb-lg-0">
                    <img src="./../images/Button.png" onClick={tootlePlay} className={"play pointer " + (play ? "hidden" : "")} alt="" />
                    <video
                      ref={video}
                      poster="./../images/Rectangle 36.png"
                      className="path__video-bg"
                      id="video__play"
                      autoPlay={false}
                      controls={play}
                      playsInline
                      preload={true}
                      loop
                      muted
                    >
                      <source
                        src="https://www.w3schools.com/tags/movie.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>

                  </div>
                  <div className="d-flex d-lg-none flex-wrap justify-content-between">
                    <div className="mb-3 mb-xl-0">
                      <h4 className="bosl color__white font__size--28 text__28-1024">
                        Basic UIUX Design for beginner
                      </h4>
                      <div className="wrapper__list-info flex-wrap">
                        <div className="items mb-3 mb-sm-0">
                          <div className="rate">
                            <div className="d-flex align-items-center">
                              <img src="./../images/Frame (sasd5).png" alt="" />
                              <img src="./../images/Frame (sasd5).png" alt="" />
                              <img src="./../images/Frame (sasd5).png" alt="" />
                              <img src="./../images/Frame (sasd5).png" alt="" />
                              <img src="./../images/Frame (sasd5).png" alt="" />
                            </div>
                            <span className="bosl font__size--18 text__18-1024 color__white">
                              (7)
                            </span>
                          </div>
                        </div>
                        <div className="items mb-3 mb-sm-0">
                          <span className="bold font__size--16 text__16-1024 color__white">
                            Intermediate
                          </span>
                        </div>
                        <div className="items mb-3 mb-sm-0">
                          <span className="bold font__size--16 text__16-1024 color__white tag">
                            Design
                          </span>
                        </div>
                        <div className="items mb-3 mb-sm-0">
                          <span className="bold font__size--16 text__16-1024 color__white">
                            12 Videos
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <a
                        href="#!"
                        className="btn__action bold font__size--16 text__16-1024 color__white"
                      >
                        <img
                          src="./../images/sdsas.png"
                          className="mr-3"
                          alt=""
                        />
                        Design
                      </a>
                      <a
                        href="#!"
                        className="btn__action bold font__size--16 text__16-1024 color__white ml-3"
                      >
                        <img
                          src="./../images/Frame (6).png"
                          className="mr-3"
                          alt=""
                        />
                        Share
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="wrapper__playlist mb-3">
                    <div className="head">
                      <h4 className="bold font__size--20 text__20-1024 color__white mb-0">
                        Playlist of topic
                      </h4>
                    </div>
                    <hr className="my-0" />
                    <div className="content">
                      <div className="scroll">
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 1
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 2
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 3
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 4
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 5
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 6
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 7
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 8
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                        <div className="item">
                          <h5 className="bold font__size--16 text__16-1024 color__white">
                            Topic 9
                          </h5>
                          <p className="mb-0 medium font__size--14 text__14-1024 color__gray-3">
                            1:32
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <NavLink to="/payment"
                    className="btn btn__white bold w-100 font__size--16 text__16-1024 color__purple shadow"
                  >
                    Buy this course ($24)
                  </NavLink>
                </div>
              </div>
              <div className="row d-none d-lg-block">
                <div className="col-md-8">
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="mb-3 mb-xl-0">
                      <h4 className="bosl color__white font__size--28 text__28-1024">
                        Basic UIUX Design for beginner
                      </h4>
                      <div className="wrapper__list-info">
                        <div className="items">
                          <div className="rate">
                            <div className="d-flex align-items-center">
                              <img src="./../images/Frame (sasd5).png" alt="" />
                              <img src="./../images/Frame (sasd5).png" alt="" />
                              <img src="./../images/Frame (sasd5).png" alt="" />
                              <img src="./../images/Frame (sasd5).png" alt="" />
                              <img src="./../images/Frame (sasd5).png" alt="" />
                            </div>
                            <span className="bosl font__size--18 text__18-1024 color__white">
                              (7)
                            </span>
                          </div>
                        </div>
                        <div className="items">
                          <span className="bold font__size--16 text__16-1024 color__white">
                            Intermediate
                          </span>
                        </div>
                        <div className="items">
                          <span className="bold font__size--16 text__16-1024 color__white tag">
                            Design
                          </span>
                        </div>
                        <div className="items">
                          <span className="bold font__size--16 text__16-1024 color__white">
                            12 Videos
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <a
                        href="#!"
                        className="btn__action bold font__size--16 text__16-1024 color__white"
                      >
                        <img
                          src="./../images/sdsas.png"
                          className="mr-3"
                          alt=""
                        />
                        Design
                      </a>
                      <a
                        href="#!"
                        className="btn__action bold font__size--16 text__16-1024 color__white ml-3"
                      >
                        <img
                          src="./../images/Frame (6).png"
                          className="mr-3"
                          alt=""
                        />
                        Share
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section>
          <div className="container">
            <ul
              className="wrapper__navtab-wrap nav nav-pills mb-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link bold font__size--28 text__28-1024 text__28-sm text__28-xxs text-capitalize color__black active"
                  id="pills-about-tab"
                  data-toggle="pill"
                  href="#pills-about"
                  role="tab"
                  aria-controls="pills-about"
                  aria-selected="true"
                >
                  about
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link bold command font__size--28 text__28-1024 text__28-sm text__28-xxs text-capitalize color__black"
                  id="pills-comment-tab"
                  data-toggle="pill"
                  href="#pills-comment"
                  role="tab"
                  aria-controls="pills-comment"
                  aria-selected="false"
                >
                  comment{" "}
                  <span className="bosl font__size--20 text__20-1024 text__20-sm color__gray-2">
                    (12)
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link bold font__size--28 text__28-1024 text__28-sm text__28-xxs text-capitalize color__black"
                  id="pills-Reviews-tab"
                  data-toggle="pill"
                  href="#pills-Reviews"
                  role="tab"
                  aria-controls="pills-Reviews"
                  aria-selected="false"
                >
                  Reviews
                </a>
              </li>
            </ul>
            <div className="row">
              <div className="col-lg-8 mb-4 mb-lg-0">
                <hr className="hr__line mt-0" />
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-about"
                    role="tabpanel"
                    aria-labelledby="pills-about-tab"
                  >
                    <p className="meidum font__size--14 text__14-1024 color__gray-2 mb-4">
                      Embark on a transformative journey through our UI/UX Design for Beginners course, meticulously curated to equip you with essential skills for success in the dynamic world of digital design. In the first phase, immerse yourself in fundamental principles of User Interface (UI) design, unraveling the art of creating visually captivating layouts and selecting harmonious color schemes. Gain a deep understanding of user interactions, ensuring your designs not only look stunning but also provide an intuitive and seamless user experience.{" "}
                    </p>
                    <p className="meidum font__size--14 text__14-1024 color__gray-2 mb-4">
                      Transitioning to User Experience (UX) design, explore intricacies of user research, information architecture, and usability testing. Grasp the significance of designing with the end user in mind, learning to craft digital experiences that resonate with diverse audiences. Our seasoned instructors will guide you through hands-on projects, empowering you to apply theoretical concepts to practical scenarios. By the course's conclusion, emerge with refined design sensibility and a robust portfolio showcasing your ability to create interfaces that captivate and engage users.{" "}
                    </p>
                    <p className="meidum font__size--14 text__14-1024 color__gray-2 mb-4">
                      At the heart of this course is a commitment to experiential learning, blending theoretical knowledge with practical application. Dive into industry-standard tools and techniques, from wireframing to prototyping, honing proficiency and confidence as a UI/UX designer. Whether aspiring to a new career or enhancing existing skill sets, this course provides the foundation needed. Join a community of passionate learners, guided by expert instructors, and unlock creative potential in the captivating field of UI/UX design. Enrich your portfolio, amplify career prospects, and become a designer who not only understands principles but also brings them to life in impactful digital experiences.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-comment"
                    role="tabpanel"
                    aria-labelledby="pills-comment-tab"
                  >
                    <div className="wrapper__write-command mb-4">
                      <img src="./../images/saad.png" className="user" alt="" />
                      <div className="write ml-3">
                        <input
                          type="text"
                          placeholder="Write a comment"
                          className="font__size--14 text__14-1024 color__gray-5"
                        />
                        <div className="send flex-shrink-0 ml-2">
                          <img src="./../images/fggfdg.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <CardCommand />
                    </div>
                    <div className="mb-4">
                      <CardCommand />
                    </div>
                    <div className="mb-4">
                      <CardCommand />
                    </div>
                    <div className="mb-4">
                      <CardCommand />
                    </div>
                    <div className="mb-4">
                      <CardCommand />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Reviews"
                    role="tabpanel"
                    aria-labelledby="pills-Reviews-tab"
                  >
                    <div className="mb-4">
                      <CardReview />
                    </div>
                    <div className="mb-4">
                      <CardReview />
                    </div>
                    <div className="mb-4">
                      <CardReview />
                    </div>
                    <div className="mb-4">
                      <CardReview />
                    </div>
                    <div className="mb-4">
                      <CardReview />
                    </div>
                    <div className="mb-4">
                      <CardReview />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="wrapper__profile-mentor mb-4">
                  <div className="head">
                    <h4 className="bold font__size--20 text__20-1024 mb-4">About Creator</h4>
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <div className="d-flex align-items-center mb-3 mb-sm-0 mb-lg-3 mb-xl-0">
                        <img
                          src="./../images/saad.png"
                          className="images__profile"
                          alt=""
                        />
                        <div className="ml-3">
                          <h5 className="bold font__size--14 text__14-1024 mb-0">
                            Dominic Lovre
                          </h5>
                          <p className="mb-0 medium font__size--12 text__12-1024">
                            Proffesional Design Mentor
                          </p>
                        </div>
                      </div>
                      <NavLink to="/mentor"
                        className="bold font__size--12 text__12-1024 btn btn__purple color__white shadow btn__profile"
                      >
                        See Full Profile
                      </NavLink>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="footer">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <div className="items">
                        <h5 className="medium font__size--12 text__12-1024 color__gray-2 mb-0">
                          Total Course
                        </h5>
                        <p className="mb-0 bold font__size--14 text__14-1024">12 Course</p>
                      </div>
                      <div className="items">
                        <h5 className="medium font__size--12 text__12-1024 color__gray-2 mb-0">
                          Rating
                        </h5>
                        <p className="mb-0 bold font__size--14 text__14-1024">
                          4,5 (20 Reviews)
                        </p>
                      </div>
                      <div className="items mt-3 mt-sm-0 mt-lg-3 mt-xl-0 d-flex align-items-center">
                        <a href="#!" className="opacity__5 hover">
                          <img src="./../images/hjghjg.png" alt="" />
                        </a>
                        <a href="#!" className="mx-3 opacity__5 hover">
                          <img src="./../images/dsd.png" alt="" />
                        </a>
                        <a href="#!" className="opacity__5 hover">
                          <img src="./../images/Frame (7).png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wrapper__rating-list">
                  <h5 className="bold font__size--20 text__20-1024">Rating</h5>

                  <div className="d-flex align-items-end">
                    <div className="rating flex-shrink-0">
                      <h5 className="bold font__size--42">4.5</h5>
                      <div className="star">
                        <img src="./../images/Frame (sasd5).png" alt="" />
                        <img src="./../images/Frame (sasd5).png" alt="" />
                        <img src="./../images/Frame (sasd5).png" alt="" />
                        <img src="./../images/Frame (sasd5).png" alt="" />
                        <img src="./../images/Frame (sasd5).png" alt="" />
                      </div>
                      <p className="medium mb-0 font__size--12 text__12-1024 color__gray-2">
                        60 Reviews
                      </p>
                    </div>
                    <div className="progress__wrap w-100 ml-3">
                      <div className="items">
                        <div className="d-flex align-items-center">
                          <img
                            src="./../images/Frame (sasd5).png"
                            className="mr-1"
                            alt=""
                          />
                          <span className="bold font__size--12 text__12-1024 mr-2">5</span>
                          <div className="progress w-100 mr-2">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "85%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span className="bold font__size--12 text__12-1024">100</span>
                        </div>
                      </div>
                      <div className="items">
                        <div className="d-flex align-items-center">
                          <img
                            src="./../images/Frame (sasd5).png"
                            className="mr-1"
                            alt=""
                          />
                          <span className="bold font__size--12 text__12-1024 mr-2">4</span>
                          <div className="progress w-100 mr-2">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "65%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span className="bold font__size--12 text__12-1024">44</span>
                        </div>
                      </div>
                      <div className="items">
                        <div className="d-flex align-items-center">
                          <img
                            src="./../images/Frame (sasd5).png"
                            className="mr-1"
                            alt=""
                          />
                          <span className="bold font__size--12 text__12-1024 mr-2">5</span>
                          <div className="progress w-100 mr-2">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "35%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span className="bold font__size--12 text__12-1024">7</span>
                        </div>
                      </div>
                      <div className="items">
                        <div className="d-flex align-items-center">
                          <img
                            src="./../images/Frame (sasd5).png"
                            className="mr-1"
                            alt=""
                          />
                          <span className="bold font__size--12 text__12-1024 mr-2">2</span>
                          <div className="progress w-100 mr-2">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "0%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span className="bold font__size--12 text__12-1024">0</span>
                        </div>
                      </div>
                      <div className="items">
                        <div className="d-flex align-items-center">
                          <img
                            src="./../images/Frame (sasd5).png"
                            className="mr-1"
                            alt=""
                          />
                          <span className="bold font__size--12 text__12-1024 mr-2">1</span>
                          <div className="progress w-100 mr-2">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "0%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span className="bold font__size--12 text__12-1024">0</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <h4 className="bold font__size--24 text__24-1024 mb-4">Related Course</h4>
            <div className="row">
              {courses.map((obj) => {
                return (
                  <div className="col-md-6 col-sm-4 col-xl-3 mb-4">
                    <CardCourse obj={obj} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Detail;
